var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "col-10 col-md-7 mx-auto main-container-terms my-15" },
    [
      _c(
        "div",
        [
          _c("v-layout", { attrs: { cols: "12" } }, [
            _c("h2", { staticClass: "text-uppercase" }, [
              _vm._v(_vm._s(_vm.$t("PrivacyPolicy.Title"))),
            ]),
          ]),
        ],
        1
      ),
      _c("div", [
        _c("p", {
          domProps: { innerHTML: _vm._s(_vm.$t("PrivacyPolicy.UpdateOn")) },
        }),
        _c("p", {
          domProps: { innerHTML: _vm._s(_vm.$t("PrivacyPolicy.P1")) },
        }),
      ]),
      _c("div", [
        _c("h3", [_vm._v(_vm._s(_vm.$t("PrivacyPolicy.CollectionAndUse")))]),
        _c("p", {
          domProps: { innerHTML: _vm._s(_vm.$t("PrivacyPolicy.P2")) },
        }),
        _c("p", {
          domProps: { innerHTML: _vm._s(_vm.$t("PrivacyPolicy.P3")) },
        }),
        _c("p", {
          domProps: { innerHTML: _vm._s(_vm.$t("PrivacyPolicy.P4")) },
        }),
        _c("p", {
          domProps: { innerHTML: _vm._s(_vm.$t("PrivacyPolicy.P5")) },
        }),
        _c("p", {
          domProps: { innerHTML: _vm._s(_vm.$t("PrivacyPolicy.P6")) },
        }),
      ]),
      _c("div", [
        _c("h3", [
          _vm._v(_vm._s(_vm.$t("PrivacyPolicy.ProtectionAndSharing"))),
        ]),
        _c("p", {
          domProps: { innerHTML: _vm._s(_vm.$t("PrivacyPolicy.P7")) },
        }),
        _c("p", {
          domProps: { innerHTML: _vm._s(_vm.$t("PrivacyPolicy.P8")) },
        }),
        _c("p", {
          domProps: { innerHTML: _vm._s(_vm.$t("PrivacyPolicy.P9")) },
        }),
        _c("p", {
          domProps: { innerHTML: _vm._s(_vm.$t("PrivacyPolicy.P10")) },
        }),
        _c("p", {
          domProps: { innerHTML: _vm._s(_vm.$t("PrivacyPolicy.P11")) },
        }),
        _c("p", [
          _vm._v(
            " " +
              _vm._s(_vm.$t("PrivacyPolicy.GoogleAnalyticsLinkInPrivacy1")) +
              " "
          ),
          _c(
            "a",
            {
              attrs: {
                href:
                  "https://policies.google.com/technologies/partner-sites?hl=" +
                  _vm.googlePolicyLanguage +
                  "&gl=uk",
                target: "_blank",
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("PrivacyPolicy.GoogleAnalyticsLinkInPrivacyLink")
                  ) +
                  " "
              ),
            ]
          ),
          _vm._v(
            " " +
              _vm._s(_vm.$t("PrivacyPolicy.GoogleAnalyticsLinkInPrivacy2")) +
              " "
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }